/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx } from 'theme-ui'
import { Hyperlink, InternalHyperlink } from '~/components/Content/RichText'

const ContentCallToAction = ({ content, ...props }) => {
  const ctaLink =
    content &&
    content[0] &&
    content[0].content &&
    content[0].content.find(({ content }) => content)

  if (!ctaLink) return null

  if (ctaLink.nodeType === 'hyperlink') {
    return (
      <Hyperlink content={ctaLink.content} data={ctaLink.data} {...props} />
    )
  }

  if (ctaLink.nodeType === 'entry-hyperlink') {
    return (
      <InternalHyperlink
        content={ctaLink.content}
        data={ctaLink.data}
        {...props}
      />
    )
  }

  return null
}

ContentCallToAction.propTypes = {
  content: PropTypes.arrayOf(PropTypes.shape({}))
}

export default ContentCallToAction
