import PropTypes from 'prop-types';
import React from 'react';
import ImageHero from '~/components/Generic/ImageHero';

const ContentHero = ({ hero, gradient = false }) => (
  <ImageHero
    heading={hero.heading}
    subheading={hero.subheading}
    image={hero.image}
    gradient={gradient}
  />
);

ContentHero.propTypes = {
  hero: PropTypes.shape({
    heading: PropTypes.shape({
      heading: PropTypes.string,
    }).isRequired,
    image: PropTypes.shape({
      fluid: PropTypes.shape(),
    }).isRequired,
    subheading: PropTypes.shape({
      subheading: PropTypes.string,
    }),
  }),
  gradient: PropTypes.bool,
};

export default ContentHero;
