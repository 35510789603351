/** @jsx jsx */
import PropTypes from 'prop-types';
import { useState, Fragment } from 'react';
import { Button, Flex, Text, Spinner, jsx } from 'theme-ui';
import { useCart, useTranslate } from '@arfabrands/gatsby-theme-assembly';

const ProductAddToCartButton = ({
  soldOut = false,
  comingSoon = false,
  disabled = false,
  price,
  quantity,
  color,
  regularPrice,
  sku,
  handleAddToCart,
  ...props
}) => {
  const translate = useTranslate();
  const { addToCart } = useCart();
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);

  const handleSubmit = async (event) => {
    if (event) event.preventDefault();
    if (disabled || comingSoon || soldOut) return;

    setButtonIsLoading(true);
    setApiError(null);

    try {
      await addToCart(sku, quantity);
    } catch (error) {
      setApiError(translate('error.api.default'));
    }

    setButtonIsLoading(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Button
        disabled={disabled || comingSoon || soldOut}
        {...props}
        p={0}
        type="submit"
        sx={{
          width: '100%',
          display: 'block',
          fontSize: ['13px', null, '16px'],
        }}
      >
        <Flex sx={{ height: '100%' }}>
          <Flex
            sx={{
              alignItems: 'center',
              flexGrow: 1,
              height: '100%',
              justifyContent: 'center',
              padding: ['0 16px', null, '0 32px'],
            }}
          >
            {buttonIsLoading && (
              <Spinner data-testid="spinner" size="15" color="inherit" />
            )}
            {!buttonIsLoading && (
              <Fragment>
                {comingSoon && translate('product.coming_soon')}
                {soldOut && translate('product.sold_out')}
                {!comingSoon && !soldOut && translate('product.add_to_cart')}
              </Fragment>
            )}
          </Flex>
        </Flex>
      </Button>
      {apiError && (
        <Text color="errorDark" variant="textLink" mt="1.5rem">
          {apiError.toString()}
        </Text>
      )}
    </form>
  );
};

ProductAddToCartButton.propTypes = {
  soldOut: PropTypes.bool,
  comingSoon: PropTypes.bool,
  disabled: PropTypes.bool,
  price: PropTypes.number,
  quantity: PropTypes.number.isRequired,
  regularPrice: PropTypes.number,
  sku: PropTypes.string.isRequired,
};

export default ProductAddToCartButton;
