/** @jsx jsx */
import { Link } from 'gatsby'
import { jsx } from 'theme-ui'
import PropTypes from 'prop-types'
import { useAnalytics } from '@arfabrands/gatsby-theme-assembly'

const CollectionLink = ({ title, slug, children, sx, ...props }) => {
  const { trackClickCollection } = useAnalytics()

  const url = `/shop/?collection=${slug}`

  return (
    <Link
      to={url}
      onClick={() => trackClickCollection(title, slug, url)}
      sx={{
        ...sx
      }}
      {...props}
    >
      {children}
    </Link>
  )
}

CollectionLink.propTypes = {
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default CollectionLink
