/** @jsx jsx */
import PropTypes from 'prop-types'
import { Container, Flex, jsx } from 'theme-ui'
import ArticleSection from './Article'
import CollectionSection from './Collection'
import ContentCardSection from './ContentCard'
import ProductSection from './Product'
import QuoteSection from './Quote'
import TextSection from './Text'

const mediaType = media => {
  return media[0].__typename
}

const ContentSection = ({ section }) => {
  return (
    <Flex
      backgroundColor="background"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Container>
        {section.media && section.media.length > 0 ? (
          <div>
            {mediaType(section.media) === 'ContentfulProduct' && (
              <ProductSection
                products={section.media}
                title={section.title}
                description={section.description}
                cta={section.cta}
              />
            )}

            {mediaType(section.media) === 'ContentfulCollection' && (
              <CollectionSection
                collections={section.media}
                title={section.title}
                description={section.description}
              />
            )}

            {mediaType(section.media) === 'ContentfulQuote' && (
              <QuoteSection quotes={section.media} />
            )}

            {mediaType(section.media) === 'ContentfulContentCard' && (
              <TextSection
                title={section.title}
                description={section.description}
                cta={section.cta}
              >
                <ContentCardSection contentCards={section.media} />
              </TextSection>
            )}

            {mediaType(section.media) === 'ContentfulArticle' && (
              <ArticleSection
                title={section.title}
                description={section.description}
                articles={section.media}
              />
            )}
          </div>
        ) : (
          <TextSection
            title={section.title}
            description={section.description}
            cta={section.cta}
          />
        )}
      </Container>
    </Flex>
  )
}

ContentSection.propTypes = {
  section: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.shape({
      description: PropTypes.string
    }),
    media: PropTypes.arrayOf(PropTypes.shape({}))
  })
}

export default ContentSection
