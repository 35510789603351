/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'

export const Hyperlink = ({ content = [], data, ...props }) => {
  return (
    <a
      sx={{ variant: 'text.link' }}
      href={data.uri}
      target="_blank"
      rel="noreferrer"
      {...props}
    >
      {content[0] && content[0].value}
    </a>
  )
}

export const InternalHyperlink = ({ content = [], data, ...props }) => {
  return (
    <Link
      sx={{ variant: 'text.link' }}
      to={`/${data.target.fields.slug}/`}
      {...props}
    >
      {content[0] && content[0].value}
    </Link>
  )
}
