/** @jsx jsx */
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { Box, Card, Flex, jsx } from 'theme-ui';
import ProductAddToCartButton from '~/components/Product/AddToCartButton';
import ProductLink from '~/components/Product/Link';

const ProductCard = ({ product }) => {
  const {
    collection: allCollections = [],
    soldOut,
    comingSoon,
    mainImage,
    name,
    price,
    regularPrice,
    sku,
    slug,
    shortDescription,
  } = product;

  const collection = allCollections.find((c) => c);

  return (
    <Card
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'left',
        flexDirection: 'column',
      }}
    >
      <Flex
        sx={{
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <ProductLink slug={slug}>
          <Img
            fluid={mainImage.fluid}
            alt={mainImage.title}
            key={mainImage.title}
          />
        </ProductLink>

        <Flex
          pt={['0.5rem', '0.5rem', '1.25rem']}
          sx={{
            justifyContent: 'flex-start',
            alignItems: 'left',
            flexDirection: 'column',
          }}
        >
          <ProductLink
            sx={{
              display: 'block',
              textTransform: 'none',
              variant: ['text.h3', 'text.h3', 'text.h3'],
              textAlign: 'left',
              marginBottom: ['4px', null, '6px'],
            }}
            slug={slug}
          >
            {name}
          </ProductLink>
          <ProductLink
            sx={{
              display: 'block',
              textTransform: 'none',
              variant: 'text.small',
              textAlign: 'left',
            }}
            slug={slug}
          >
            {shortDescription}
          </ProductLink>
        </Flex>
      </Flex>
      <Box>
        <Flex
          color="primary"
          sx={{
            justifyContent: 'space-between',
            paddingTop: ['6px', null, '12px'],
            paddingBottom: ['8px', '8px', '16px'],
          }}
        >
          {collection && (
            <ProductLink
              sx={{
                variant: 'text.navLink',
              }}
              slug={slug}
            >
              {collection.title}
            </ProductLink>
          )}
          {/* {size && (
            <ProductLink
              sx={{
                variant: 'text.navLink',
              }}
              slug={slug}
            >
              {size}
            </ProductLink>
          )} */}
        </Flex>
        <ProductAddToCartButton
          soldOut={soldOut}
          comingSoon={comingSoon}
          price={price}
          quantity={1}
          regularPrice={regularPrice}
          sku={sku}
          sx={{ width: '100%' }}
        />
      </Box>
    </Card>
  );
};

ProductCard.propTypes = {
  product: PropTypes.shape({
    collection: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
        title: PropTypes.string,
      })
    ),
    comingSoon: PropTypes.bool,
    soldOut: PropTypes.bool,
    mainImage: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
    }),
    name: PropTypes.string,
    price: PropTypes.number,
    regularPrice: PropTypes.number,
    // size: PropTypes.string,
    sku: PropTypes.string,
    slug: PropTypes.string,
    shortDescription: PropTypes.string,
  }),
};

export default ProductCard;
