/** @jsx jsx */
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { Box, Flex, Heading, Text, jsx } from 'theme-ui'

const ContentCardSection = ({ contentCards }) => {
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: ['16px', null, '48px']
      }}
    >
      {contentCards.map(({ id, title, description, label, media }) => {
        return (
          <Flex
            key={id}
            sx={{
              maxWidth: '1200px',
              marginBottom: ['16px', null, '32px'],
              flexDirection: ['column', null, 'row'],
              ':nth-of-type(even)': {
                flexDirection: [null, null, 'row-reverse']
              }
            }}
          >
            <Flex sx={{ width: ['100%', null, '50%'] }}>
              <Img
                fluid={media.fluid}
                title={media.title}
                sx={{ width: '100%' }}
              />
            </Flex>
            <Flex
              sx={{
                flexDirection: 'column',
                width: ['100%', null, '50%'],
                padding: ['48px 28px', '48px 36px', '64px 96px 64px 64px'],
                justifyContent: 'center'
              }}
            >
              <Text variant="link" sx={{ marginBottom: '0.5rem' }}>
                {label}
              </Text>
              <Heading as="h3" variant="h3">
                {title}
              </Heading>
              <Box
                dangerouslySetInnerHTML={{
                  __html: description.childMarkdownRemark.html
                }}
              />
            </Flex>
          </Flex>
        )
      })}
    </Flex>
  )
}

ContentCardSection.propTypes = {
  quotes: PropTypes.arrayOf(PropTypes.shape({}))
}

export default ContentCardSection
